body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print {
  body {
    margin: 0;
    padding: 0 !important;
    min-width: 768px;
  }
  .container {
    width: auto;
    min-width: 750px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box-border--solid {
  border: solid 1px;
  border-color: #000000;
  overflow: visible;
}
.space-v-200 {
  width: 1px;
  height: 200px;
}

.signature-space {
  margin-top: 190px;
}

.text-right {
  text-align: right;
}
.h-300 {
  height: 300px;
}
